import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_ID,
  ErrorHandler,
  Provider,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideClientHydration, withEventReplay, withIncrementalHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  Router,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CustomErrorHandlerService } from './error-handler';
import { NetworkInterceptor } from './network-interceptor';
import { AppConfigService } from './providers/config.service';
import { routes } from './routes';
import { AnalyticsService } from './services/analytics.service';
import { BrowserService } from './services/browser.service';
import { MetaService } from './services/meta.service';
import { ScrollService } from './services/scroll.service';
import { tenantRoutes } from './tenant-routes';

function initializeApp(
  scroll: ScrollService,
  an: AnalyticsService,
  meta: MetaService,
  config: AppConfigService,
  maintenance: MaintenanceService,
  browserService: BrowserService,
  router: Router
) {
  return () => {
    const routes = getRoutes(browserService);
    if (routes) router.resetConfig(routes);
    console.debug('ANGULAR_INIT_APP');
  };
}

function getRoutes(browserService: BrowserService) {
  const isTenantDomain = browserService.isTenantDomain();
  if (isTenantDomain) return tenantRoutes;
}

import { CANNY } from './canny';
import { GROWTHBOOK, gb } from './growthbook';
import { HANDLE_META } from './handle-meta';
import { PICFLOW_MAINTENANCE } from './maintenance';
import { PICFLOW_ANALYTICS } from './picflow-analytics';
import { PICFLOW_CLERK } from './picflow-clerk';
import { PICFLOW_CONFIG } from './picflow-config';
import { PICFLOW_STORYBLOK } from './picflow-storyblok';
import { CannyService } from './services/canny.service';
import { GrowthBookExperimentService } from './services/growth-book-experiment.service';
import { MaintenanceService } from './services/maintenance.service';
import { StoryblokService } from './services/storyblok.service';

export const appConfig = {
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(withIncrementalHydration(), withEventReplay()),
    provideAnimations(),
    CookieService,
    { provide: APP_ID, useValue: 'picflow' },
    <Provider>{ provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    {
      provide: GROWTHBOOK,
      useFactory: (service: GrowthBookExperimentService) => gb(service.onExperimentViewed),
      deps: [GrowthBookExperimentService],
    },
    {
      provide: PICFLOW_MAINTENANCE,
      useFactory: (maintenance: MaintenanceService) => maintenance,
      deps: [MaintenanceService],
    },
    { provide: PICFLOW_CONFIG, useFactory: (config: AppConfigService) => config, deps: [AppConfigService] },
    { provide: PICFLOW_ANALYTICS, useFactory: (analytics: AnalyticsService) => analytics, deps: [AnalyticsService] },
    {
      provide: PICFLOW_CLERK,
      useFactory: (config: AppConfigService) => {
        return async () => {
          const { Clerk } = await import('@clerk/clerk-js');
          return new Clerk(config.clerkKey);
        };
      },
      deps: [AppConfigService],
    },
    { provide: HANDLE_META, useFactory: (meta: MetaService) => meta.handleMeta.bind(meta), deps: [MetaService] },
    { provide: CANNY, useFactory: (canny: CannyService) => canny, deps: [CannyService] },
    { provide: PICFLOW_STORYBLOK, useFactory: (canny: StoryblokService) => canny, deps: [StoryblokService] },
    provideAppInitializer(() => {
      const initializerFn = initializeApp(
        inject(ScrollService),
        inject(AnalyticsService),
        inject(MetaService),
        inject(AppConfigService),
        inject(MaintenanceService),
        inject(BrowserService),
        inject(Router)
      );
      return initializerFn();
    }),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ),
    provideZoneChangeDetection({
      runCoalescing: true,
      eventCoalescing: true,
    }),
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
  ],
};
